$breakpoints: (
  'xs': null,
  'sm': 768px,
  'md': 992px,
  'lg': 1200px,
  'xlg': 1400px,
);

$header-height: 50px;
$header-height-v2: 72px;

$nav-container-width: 50px;
$nav-container-width-full: 190px;
$nav-container-width-v2: 88px;
$nav-container-width-full-v2: 200px;
