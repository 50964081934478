@import 'compass/css3';
@import 'compass/utilities';

@import '_base/colors';

$nav-bg-color: $color-whalegrey;
$nav-hover-color: lighten($nav-bg-color, 5);
$nav-active-color: darken($nav-bg-color, 10);
$nav-badge-height: 24px;

.l-nav-container {
  width: 100%;
  grid-area: navigation;

  .nav-info {
    position: absolute;
    bottom: 0;
    text-align: center;
    padding: 20px 10px;

    a {
      display: inline;
      padding: initial;
      margin: 0 3px;
      line-height: 22px;
      font-size: 11px;
      font-weight: 400;
      text-transform: capitalize;
      color: #97999a;
      white-space: nowrap;
      cursor: pointer;
    }

    .nav-info_icon {
      font-size: 32px;
      cursor: default;
    }
  }

  @media (max-width: 1160px) {
    .nav-info {
      display: none;
    }
  }
}

react-navigation{
  display: block;

  // width: 100%;
  height: 100%;

  > nav {
    height: 100%;

    padding-bottom: 150px;
  }
}

navigation-link {
  display: block;
}
