/*------------------------------------*\
    # Z INDEX VARIABLES
\*------------------------------------*/

$z-index-1: 1000;
$z-index-2: 2000;
$z-index-3: 3000;
$z-index-4: 4000;
$z-index-5: 5000;
$z-index-6: 6000;
$z-index-7: 7000;
$z-index-8: 8000;
$z-index-9: 9000;

/*------------------------------------*\
    # Z-INDEX APPLICATIONS
\*------------------------------------*/

$z-index-tooltips: $z-index-6;
$z-index-overlay: $z-index-7;
$z-index-notifications: $z-index-8;
$z-index-click-triggers: $z-index-9;

/*------------------------------------*\
    # Z-INDEX OVERRIDES
\*------------------------------------*/

.tooltip {
  z-index: $z-index-tooltips;
}

.litebox-overlay {
  z-index: $z-index-overlay;
}

.select2-drop-mask {
  z-index: $z-index-click-triggers;
}

.daterangepicker {
  z-index: $z-index-tooltips;
}

#image-viewer {
  z-index: $z-index-overlay;
}

.l-disclaimer-container {
  z-index: $z-index-notifications;
}

.menu-content {
  z-index: $z-index-overlay;
}
