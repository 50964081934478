@import '_base/configs';
@import 'compass/css3/animation';

@mixin responsive($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media (min-width: #{map-get($breakpoints, $breakpoint)}) {
      @content;
    }
  } @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Please make sure it is defined in `$breakpoints` map.";
  }
}

@mixin text-ellipsis($max-width: 100px) {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: $max-width;
}

@mixin font($size: 12, $weight: normal, $style: normal, $color: #333) {
  font-size: $size;
  font-weight: $weight;
  font-style: $style;
  color: $color;
}

@mixin untouchable() {
  -webkit-user-select: none; /* Chrome/Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+ */

  /* Rules below not implemented in browsers yet */
  -o-user-select: none;
  user-select: none;
}

@mixin absolute_all() {
  position: absolute;

  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  @content;
}

@mixin child($element) {
  &_#{$element} {
    @content;
  }
}

@mixin modifier($element) {
  &--#{$element} {
    @content;
  }
}

@mixin arrow($direction, $color, $size) {
  display: block;
  height: 0;
  width: 0;

  @if $direction == 'top' {
    border-left: $size solid transparent;
    border-right: $size solid transparent;
    border-bottom: $size solid $color;
  } @else if $direction == 'right' {
    border-top: $size solid transparent;
    border-bottom: $size solid transparent;
    border-left: $size solid $color;
  } @else if $direction == 'bottom' {
    border-top: $size solid $color;
    border-right: $size solid transparent;
    border-left: $size solid transparent;
  } @else if $direction == 'left' {
    border-top: $size solid transparent;
    border-right: $size solid $color;
    border-bottom: $size solid transparent;
  } @else if $direction == 'top-left' {
    border-top: $size solid $color;
    border-right: $size solid transparent;
  } @else if $direction == 'top-right' {
    border-top: $size solid $color;
    border-left: $size solid transparent;
  } @else if $direction == 'bottom-left' {
    border-bottom: $size solid $color;
    border-right: $size solid transparent;
  } @else if $direction == 'bottom-right' {
    border-bottom: $size solid $color;
    border-left: $size solid transparent;
  }
}

@function palette($palette, $shade: 'base') {
  @return map-get(map-get($palettes, $palette), $shade);
}

@mixin angular-animate($enter, $leave, $duration: 0.3s) {
  &.ng-enter {
    @include animation($enter $duration);
  }

  &.ng-leave {
    @include animation($leave $duration);
  }
}

@mixin keyf($animationName) {
  @-webkit-keyframes #{$animationName} {
    @content;
  }
  @-moz-keyframes #{$animationName} {
    @content;
  }
  @-o-keyframes #{$animationName} {
    @content;
  }
  @keyframes #{$animationName} {
    @content;
  }
}

@mixin make-pulse($animationName, $color) {
  @include keyf($animationName) {
    0% {
      @include box-shadow(0 0 0 0 rgba($color, 0.4));
    }
    70% {
      @include box-shadow(0 0 0 10px rgba($color, 0));
    }
    100% {
      @include box-shadow(0 0 0 0 rgba($color, 0));
    }
  }
}
