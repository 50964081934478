@font-face {
  font-family: 'procurify-icons';
  font-style: normal;
  font-weight: normal;
  src: url('./icons.eot');
  src: url('./icons.eot') format('embedded-opentype'),
    url('./icons.ttf') format('truetype'), url('./icons.woff') format('woff'),
    url('./icons.svg') format('svg');
}

[class^='icon-'],
[class*='icon-'] {
  /* Better Font Rendering =========== */
  display: inline-block;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: 'procurify-icons';
  font-size: inherit;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-transform: none;
  speak: none;
  text-rendering: auto;
}

.icon-home:before {
  content: '\e600';
}

.icon-request:before {
  content: '\e601';
}

.icon-approval:before {
  content: '\e602';
}

.icon-procure:before {
  content: '\e603';
}

.icon-receive:before {
  content: '\e604';
}

.icon-inventory:before {
  content: '\e605';
}

.icon-payment:before {
  content: '\e606';
}

.icon-manage:before {
  content: '\e607';
}

.icon-settings:before {
  content: '\e609';
}

.icon-add:before {
  content: '\e60a';
}

.icon-chevron-left:before {
  content: '\e60b';
}
.icon-times:before {
  content: '\e60c';
}
.icon-delete:before {
  content: '\e60d';
}
.icon-edit:before {
  content: '\e60e';
}
.icon-attach:before {
  content: '\e60f';
}

.icon-search:before {
  content: '\e610';
}

.icon-filter:before {
  content: '\e611';
}

.icon-link:before {
  content: '\e612';
}

.icon-more:before {
  content: '\e613';
}

.icon-download:before {
  content: '\e614';
}

.icon-export:before {
  content: '\e615';
}

.icon-import:before {
  content: '\e616';
}

.icon-archive:before {
  content: '\e617';
}

.icon-duplicate:before {
  content: '\e618';
}

.icon-action-history:before {
  content: '\e619';
}

.icon-fail:before {
  content: '\e61a';
}

.icon-approve:before {
  content: '\e61b';
}

.icon-question:before {
  content: '\e61c';
}

.icon-close:before {
  content: '\e61d';
}

.icon-email:before {
  content: '\e61e';
}

.icon-folder:before {
  content: '\e61f';
}

.icon-lock:before {
  content: '\e620';
}

.icon-message:before {
  content: '\e621';
}

.icon-flight:before {
  content: '\e622';
}

.icon-labour:before {
  content: '\e623';
}

.icon-lodge:before {
  content: '\e624';
}

.icon-meals:before {
  content: '\e625';
}

.icon-mileage:before {
  content: '\e626';
}

.icon-other:before {
  content: '\e627';
}

.icon-print:before {
  content: '\e628';
}

.icon-record:before {
  content: '\e629';
}

.icon-report:before {
  content: '\e62a';
}

.icon-reset:before {
  content: '\e62b';
}

.icon-save:before {
  content: '\e62c';
}

.icon-schedule:before {
  content: '\e62d';
}

.icon-chevron-thick-left:before {
  content: '\e800';
}

.icon-chevron-thick-right:before {
  content: '\e801';
}

.icon-chevron-thick-down:before {
  content: '\e802';
}

.icon-chevron-thick-up:before {
  content: '\e803';
}

.icon-order:before {
  content: '\e804';
}

.icon-edit-log:before {
  content: '\e805';
}

.icon-get-started:before {
  content: '\e806';
}

.icon-procurify:before {
  content: '\e807';
}

.icon-empty-cart:before {
  content: '\e900';
}

.icon-attention:before {
  content: '\e901';
}

.icon-ap:before {
  content: '\e902';
}

.icon-catalog:before {
  content: '\e903';
}

.icon-map:before {
  content: '\e904';
}

.icon-camera:before {
  content: '\e905';
}

.icon-creditcard:before {
  content: '\e906';
}

.icon-auditlog:before {
  content: '\e907';
}

.icon-checkmark:before {
  content: '\e908';
}

.icon-gallery:before {
  content: '\e909';
}

.icon-notification:before {
  content: '\e6910';
}

.icon-sort-ascending:before {
  content: '\e911';
}

.icon-sort-descending:before {
  content: '\e912';
}

.icon-switch:before {
  content: '\e913';
}

.icon-upload:before {
  content: '\e943';
}

.icon-chevron-thick-both:before {
  content: '\e915';
}

.icon-emptystate:before {
  content: '\e90a';
}

.icon-unlock:before {
  content: '\e90b';
}

.icon-vacation:before {
  content: '\e90c';
}

.icon-audit-arrow:before {
  content: '\e90d';
}

.icon-timer:before {
  content: '\e90e';
}

.icon-disable:before {
  content: '\e90f';
}

.icon-pointer-down:before {
  content: '\e914';
}

.icon-pointer-up:before {
  content: '\e916';
}

.icon-pointer-left:before {
  content: '\e917';
}

.icon-pointer-right:before {
  content: '\e918';
}

.icon-csv:before {
  content: '\e919';
}

.icon-zip:before {
  content: '\e91a';
}

.icon-pause:before {
  content: '\e91b';
}

.icon-attention-sym:before {
  content: '\e91c';
}

.icon-question-sym:before {
  content: '\e91d';
}

.icon-star-solid:before {
  content: '\e91e';
}

.icon-openbox:before {
  content: '\e91f';
}

.icon-resume:before {
  content: '\e920';
}

.icon-star-half:before {
  content: '\e921';
}

.icon-visible:before {
  content: '\e925';
}

.icon-view-card:before {
  content: '\e922';
}

.icon-view-list:before {
  content: '\e923';
}

.icon-forward:before {
  content: '\e924';
}

.icon-minus:before {
  content: '\e910';
}

.icon-user:before {
  content: '\e927';
}

.icon-crop:before {
  content: '\e928';
}

.icon-zoom-in:before {
  content: '\e92a';
}

.icon-zoom-out:before {
  content: '\e92b';
}

.icon-rotate:before {
  content: '\e929';
}

.icon-add-receipt:before {
  content: '\e926';
}
