/* ------------------------------------*\
    #COLORS
\*------------------------------------ */

// Primary
$color-white: #ffffff;
$color-darkgreen: #002e33;

// Secondary
$color-green: #31936a;
$color-red: #bc5750;
$color-yellow: #faab18;

// Tertiary
$color-aquamarine: #d2fbef;
$color-blue: #45ace4;
$color-purple: #8886e9;
$color-teal: #26bcb9;

// Neutral
$color-offwhite: #f8f8fa;
$color-lightgrey: #bfbfbf;
$color-grey: #404040;

// Alias
$color-darkgrey: $color-grey;
$color-whalegrey: $color-darkgrey;
$color-teal: $color-darkgreen;
$color-navy: $color-darkgreen;
$color-orange: $color-yellow;

// Content
$color-field-disabled: #e3e3e3;
$color-border: $color-lightgrey; // use for borders
$color-background: $color-offwhite;
$color-content-background: $color-white;

// Contextual
$color-primary: $color-darkgreen;
$color-secondary: $color-yellow;

$color-success: $color-green;
$color-danger: $color-red;
$color-warning: $color-yellow;
$color-caution: $color-yellow;
$color-info: $color-blue;
$color-disabled: $color-lightgrey;
$color-highlight: tint($color-aquamarine, 50%);

$color-text-primary: shade($color-darkgreen, 50%);
$color-text-secondary: $color-grey;
$color-text-tertiary: $color-grey;
$color-text-inverse: $color-white;
$color-text-placeholder: $color-grey;
$color-text-disabled: $color-lightgrey;
$color-text-link: shade($color-blue, 30%);

// File Types
$color-file-pdf: $color-red;
$color-file-excel: $color-green;
$color-file-word: $color-blue;
$color-file-zip: $color-yellow;

// Palette
$palette: (
  'white': $color-white,
  'offwhite': $color-offwhite,
  'lightgrey': $color-lightgrey,
  'grey': $color-grey,
  'darkgrey': $color-darkgrey,
  'whalegrey': $color-whalegrey,
  'aurora': $color-green,
  'flux': $color-darkgreen,
  'soulflare': $color-yellow,
  'turbo': $color-red,
  'nova': $color-blue,
  'nebula': $color-purple,
  'abyss': $color-darkgreen,
);

// Color Classes
$colors: (
  'green': $color-green,
  'blue': $color-blue,
  'teal': $color-teal,
  'red': $color-red,
  'orange': $color-yellow,
  'purple': $color-purple,
  'navy': $color-navy,
  'whale-grey': $color-whalegrey,
  'darkgrey': $color-darkgrey,
  'lightgrey': $color-lightgrey,
  'grey': $color-grey,
  'basic': $color-text-primary,
  'success': $color-success,
  'danger': $color-danger,
  'warning': $color-warning,
  'caution': $color-grey,
  'info': $color-info,
  'white': $color-white,
  'pdf': $color-file-pdf,
  'excel': $color-file-excel,
  'word': $color-file-word,
  'zip': $color-file-zip,
  'disabled': $color-text-disabled,
  'secondary': $color-text-secondary,
);

// Status Colors
$statuses: (
  'all': $color-darkgrey,
  'pending': $color-yellow,
  'approved': $color-green,
  'purchased': $color-green,
  'denied': $color-red,
  'received': $color-green,
  'open': $color-yellow,
  'partial': $color-yellow,
  'closed': $color-green,
  'cancelled': $color-red,
  'paid': $color-green,
  'overdued': $color-red,
  'draft': $color-yellow,
  'rejected': $color-red,
);

$color-status-success: tint($color-green, 60%);
$color-status-error: tint($color-red, 60%);
$color-status-warning: tint($color-yellow, 50%);
$color-status-info: tint($color-blue, 60%);
$color-status-inactive: shade($color-white, 5%);
$color-status-new: $color-aquamarine;
