@import 'compass/css3/animation';
@import '_base/z-index';

.app-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: $z-index-1;

  color: #fff;

  line-height: 1;
  height: $header-height;

  background: $color-green;
  @include box-shadow(0 1px 3px rgba(0, 0, 0, 0.2));

  .vacation-badge {
    background: #faab18;
    border-radius: 100%;
    padding: 3px;
    font-size: 1rem;
  }
  &.vacation-mode {
    .vacation-popover {
      @include box-shadow(0 0 10px rgba(0, 0, 0, 0.3));
      border-radius: 2px;
      border-color: #eee;
      opacity: 0.9 !important;
      width: auto;
      max-width: none;
      min-height: auto;
      .btn--cancel-delegation {
        color: black;
        display: inline-block;
        vertical-align: middle;

        &:hover {
          color: #002e33;
        }
      }
      .approval-timer-icons {
        color: black;
        font-size: 30px;
        vertical-align: middle;

        .icon-approval {
          color: black;
          // font-size: 30px;
        }

        .icon-timer.timer-badge {
          background: #faab18;
          border-radius: 100%;
          color: #eee;
          font-size: 12px;
          padding: 3px;
          position: absolute;
          top: 30px;
          left: 32px;
        }
      }

      .popover-content {
        padding: 1em;
      }
    }
  }
}

.header-logo {
  height: $header-height;
  width: $nav-container-width-full;
  display: block;
  float: left;
  line-height: $header-height;
  padding-left: 20px;

  img {
    height: auto;
    max-width: 155px;
    vertical-align: middle;
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    -ms-interpolation-mode: nearest-neighbor;
  }
}

.header-profile {
  text-align: left;
  position: relative;
  height: $header-height;
  line-height: $header-height;

  min-width: 100px;
  max-width: 300px;
  padding-left: 12px;
  padding-right: 12px;
  display: inline-block;

  white-space: nowrap;

  @include untouchable();

  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  // border-left: 1px solid darken($color-green,5);

  > i {
    font-size: 1rem;
  }

  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }

  #header-profile {
    border: none;
    outline: none;
    width: 0;
    height: 0;
    position: absolute;
    visibility: hidden;
  }

  #header-profile:checked + .dropdown-menu {
    display: block;
  }

  .dropdown-menu {
    @include border-radius(0);
    margin-top: 0;
    right: 0px;
  }
}

.header-profile-image {
  vertical-align: middle;

  border: 1px solid white;
  position: relative;
  @include border-radius(5px);
}

.header-profile-name {
  display: inline-block;
  vertical-align: middle;

  color: #fff;
  line-height: $header-height - 10;
  font-size: 12px;
  font-weight: 400;
  text-transform: uppercase;

  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 0;
  &.delegate {
    text-align: right;
    line-height: 1;
    vertical-align: middle;
    min-width: 85px;
    .header-profile-status {
      display: block;
      font-size: 9px;
      padding: 2px 0;
      border-radius: 0 0 2px 2px;
      line-height: 1;
      top: 28px;
      text-transform: none !important;
      max-width: 80%;
    }
  }
  @include text-ellipsis(190px);

  &.mobile-only {
    display: none;
  }
}

.mobile-only .header-profile-name {
  display: none;
}

.mobile-only .header-profile-name.mobile-only {
  display: inline-block;
}

.header-content {
  font-size: 0px;
  // line-height: 50px;
  text-align: right;
}

a.btn.btn--header {
  height: 34px;
  line-height: 22px;
  color: #fff;

  display: inline-block;

  @include border-radius(5);

  &.btn--hollow {
    border: 1px solid #fff;
    font-weight: 600;
    i.icon-message {
      font-size: 20px;
      font-weight: 600;
      vertical-align: top;
    }
    .caption {
      display: inline-block;
      vertical-align: top;
    }
  }
  .btn--help {
    i {
      font-size: 18px;
    }
  }

  &:hover {
    color: #fff;
  }
}

.btn.btn--trial {
  padding-left: 40px;
  padding-top: 2px;

  .btn--trial_days {
    position: absolute;
    left: 8px;
    top: 4px;
    font-weight: bold;
    font-size: 22px;
  }

  .btn--trial_text {
    p {
      text-align: left;
      line-height: 1;
      font-size: 12px;
      margin: 0 0 2px 0;

      text-transform: none;
    }

    p.small {
      text-transform: none;
      font-size: 10px;
    }
  }
}

.help-btn {
  vertical-align: middle;
  display: inline-block;
  text-align: center;
  width: 26px;
  height: 26px;
  line-height: 24px;
  color: #fff;
  background: transparent;
  border: 2px solid #fff;
  cursor: pointer;

  @include border-radius(15px);
  @include untouchable();
  &:hover,
  &:focus,
  &.active {
    background: $color-teal;
  }
  i {
    font-size: 14px;
  }
}

.tablet-only .btn.btn--help,
.mobile-only .btn.btn--help {
  min-width: auto;
  padding-left: 1em;
  padding-right: 1em;
  > .caption {
    display: none !important;
  }
  > i {
    margin-right: 0px;
  }
}

.tablet-only .header-logo,
.mobile-only .header-logo {
  width: 60px;
  overflow: hidden;
}

// .mobile-nav-btn{
// 	display:none;

// 	height:30px;
// 	width:30px;
// 	margin-top:10px;
// 	margin-right: 15px;

// 	font-size: 24px;
// 	line-height: 30px;

// 	text-align: center;

// 	float:left;

// 	i{
// 		margin:0;
// 	}

// }

// .mobile-only .mobile-nav-btn{
// 	&.show-mobile-only{
// 		display:inline-block;
// 	}
// }

// .mobile-only .btn__shortcut-add{
// 	left: 220px;
// }
.btn-shortcut-add {
  margin-top: 1em;
  margin-left: 1em;
  i {
    transition: transform 0.1s cubic-bezier(0.25, 0.8, 0.25, 1);
  }
  &.open {
    i {
      transform: rotate(45deg) scale(1.5);
    }
  }
}
.btn__global-search {
  display: none;
  margin-top: 1em;
  margin-left: 1em;
  width: 62px;
  height: 32px;
  background: shade($color-green, 10%);
  font-weight: 600;
  border-color: shade($color-green, 10%);
  &:hover,
  &:focus {
    background-color: darken($color-green, 5);
    border: 1px solid shade($color-green, 10%);
  }
  .btn__global-search__caption {
    margin-left: 0.5em;
    font-size: 10px;
  }
}
.btn__shortcut-add {
  vertical-align: middle;
  @include transition(all 0.1s ease-in);
  position: absolute;
  top: 12px;
  left: 190px;
  z-index: 54;

  .btn__shortcut-add__icon {
    color: #fff;
    text-align: center;
    cursor: pointer;
    border: 1px solid #fff;
    font-size: 2em;
    width: 28px;
    height: 28px;
    display: inline-block;
    border-radius: 4px;

    &:hover {
      background: #fff;
      color: $color-green;
    }
  }

  .popover {
    cursor: default;
  }
}

.shortcut-popover {
  @include box-shadow(0 0 10px rgba(0, 0, 0, 0.3));
  border-radius: 2px;
  border-color: #eee;
  min-width: auto;
  width: 150px;
  margin-left: 44px;
  margin-top: 2px;
  &.shortcut-popover--request {
    background-color: transparent;
    border: none;
    box-shadow: none;
    margin-left: 0;
    margin-top: 0 !important;
    min-width: auto !important;
    width: 100px !important;
    max-width: 100px !important;
    .popover-content {
      max-width: 100px !important;
      padding-left: 0;
      padding-right: 0;
    }
    .arrow {
      display: none;
    }
    .btn {
      font-weight: 600;
      text-align: left;
      font-size: 9px;
    }
    li + li {
      margin-top: 0.5em;
    }
  }
  &.shortcut-popover--help {
    width: 220px;
    margin-left: 60px;
  }
  hr {
    border-top: none;
    border-left: none;
    border-bottom: 1px solid #eee;
  }
  .popover-title {
    line-height: 1;
    border-radius: 0px;
    background: #eee;
    color: $color-darkgrey;
    padding: 1rem;
    font-size: 14px;
    text-align: center;
    text-transform: none;
    i {
      margin-right: 5px;
    }
  }
  .arrow {
    left: 20% !important;
  }
  .shortcut-popover__link {
    color: $color-darkgrey;
    line-height: 30px;
    @include transition(color 0.1s linear);
    &:hover {
      color: $color-teal;
    }
  }

  .popover-content {
    padding: 1rem;
  }
}

.shortcut-popover.shortcut-popover--request {
  .shortcut-popover__link {
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
  }
}

.notification-popover {
  width: 400px;
  max-width: 400px;
  cursor: default;

  @include box-shadow(0 0.166em 0.333em rgba(0, 0, 0, 0.2));
  background: #f3f3f3;
  border-radius: 1px;
  color: #111;

  .comment-bubble {
    min-width: 100%;
    max-width: 100%;
    border-left: 3px solid $color-teal;
  }
  .comment-bubble-name {
    line-height: 14px;
  }

  .popover-notification-title {
    border-radius: 0px;
    color: #111;
    padding: 10px 14px;
    margin: 0;
    line-height: 20px;
    font-size: 14px;
    font-weight: 400;
    text-transform: none;

    background: #eee;

    border-bottom: 1px solid #ccc;
  }

  .comment-list {
    padding: 10px;
    max-height: 500px;
    overflow: auto;
  }

  .comment-list-item {
    @include animation(zoomin 0.5s);
  }

  .comment-list li.empty-list-item {
    @include animation(none);
  }

  .comment-list .comment-list-item + .comment-list-item {
    margin-top: 10px;
  }

  .comment-list .comment-list-item.notification--system {
    .comment-bubble {
      border-left-color: $color-orange;

      .comment-top-info {
        p {
          line-height: 16px;
          color: #999;
        }
      }
    }
  }
  .comment-list .comment-list-item.comment--empty {
    .comment-bubble .comment-top-info {
      padding-bottom: 0px;
    }
  }

  .notification-inbox-btn {
    display: block;
    line-height: 1;
    padding: 2em 14px;

    background: #eee;

    border-top: 1px solid #ccc;
    text-align: center;
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 600;
    color: #111;
  }
}

.header-section {
  font-size: 1rem;
  position: relative;
  display: inline-block;

  vertical-align: middle;
  width: $header-height;
  line-height: $header-height - 2;
  height: $header-height;
  padding: 0 10px;

  color: #fff;
  border-left: 1px solid darken($color-green, 5);
  border-right: 1px solid darken($color-green, 5);

  text-align: center;

  .header-section-icon {
    vertical-align: middle;
    font-size: 20px;
  }

  .header-section-badge {
    position: absolute;
    display: block;
    line-height: 1;

    top: 1px;
    right: -1px;
    font-size: 11px;

    padding: 2px 4px;
    border-radius: 2px;
    background: $color-red;
  }

  &:hover,
  &:active,
  &:focus {
    color: #fff;
    background: rgba(0, 0, 0, 0.1);
  }

  & + .header-section {
    border-left: none;
  }
}

.btn--header + .header-section {
  margin-left: 10px;
}

.header-domain-switch {
  display: none;
}

.header-domain-switch-select {
  height: 34px;
  font-size: 11px;
  vertical-align: middle;

  max-width: 100px;
}
